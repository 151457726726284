export const privacyPolicyLink =
  "https://drive.google.com/file/d/1Aii6mtYV7e_aqwZSUTBrjLwDhFfJ5cMH/view?usp=sharing";
export const termsOfUseLink =
  "https://drive.google.com/file/d/12DO_h1IU8a8_-coJoKhlR-tRHIfm8Ach/view?usp=sharing";
export const linkedinLink = "https://www.linkedin.com/company/codekerdos/";
export const youtubeLink =
  "https://youtube.com/@codekerdos?si=Pppi6GLKEEG3G0bx";
export const instagramLink =
  "https://www.instagram.com/code_kerdos_/profilecard/?igsh=MTZlbnQ3dWxoZmJ4Mg==";
export const facebookLink =
  "https://www.facebook.com/people/CodeKerdos/61569706247481/?mibextid=ZbWKwL";
